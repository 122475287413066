<template>
  <div class="portalWebsite">
    <Header @getHeaderState="getHeaderState" />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :style="{'min-height': routerViewHeight}" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :style="{'min-height': routerViewHeight}" />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
export default {
  components: { Footer, Header },
  data() {
    return {
      routerViewHeight: 'calc(100vh - 314px)'
    }
  },
  methods: {
    getHeaderState(index) {
      switch (index) {
        case 6:
          this.routerViewHeight = 'calc(100vh - 174px)'
          break

        default:
          this.routerViewHeight = 'calc(100vh - 314px)'
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.portalWebsite{
  width: 100%;
  background: rgba(249,249,249,1);
}
</style>
