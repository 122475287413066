<template>
  <header
    class="headerPageBox"
    :style="isNeedIndex === 3||isNeedIndex === 6 ? 'background-color:#f9f9f9;box-shadow:none' : ''"
  >
    <div
      class="topBox"
      :style="
        $route.path === '/news/newsDetail' ? 'background-color:#f9f9f9' : ''
      "
    >
      <!-- 顶部那些导航栏 -->
      <div class="top">
        <div class="left">
          <span>369叁陆玖欢迎你！</span>
          <router-link v-if="isLogin" class="toLogin" :to="{ path: '/login' }">
            请<span class="color">登录/</span>
          </router-link>
          <router-link v-if="isLogin" class="color" :to="{ path: '/register' }">
            免费注册
          </router-link>
          <el-dropdown v-else @command="handleDropdownClick">
            <span class="userName">
              {{ userInfo.userName || userInfo.orgName }}
              <i class="icon el-icon-arrow-down" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>账号设置</el-dropdown-item> -->
              <el-dropdown-item command="logOut">
                退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="right">
          <div v-for="(item, index) in rightArr" :key="index" class="rightItem">
            <el-dropdown v-if="item.child">
              <div class="rightLine">
                <!-- <i v-if="index === 0" class="collect el-icon-star-off" /> -->
                <span @click="JumpScm(item)">{{ item.name }}</span>
                <i v-if="item.child" class="icon el-icon-arrow-down" />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="val in item.child" :key="val.name">
                  <div class="hiddenMenu" @click="JumpScm(item, val)">
                    {{ val.name }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div v-else class="rightLine" @click="skipUrl(item)">
              <span>{{ item.name }}</span>
              <i v-if="item.child" class="icon el-icon-arrow-down" />
            </div>
            <!-- 下面隐藏的菜单 -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="isNeedIndex !== 6">
      <!-- 中间那个搜索框 -->
      <!-- 订单支付 -->
      <div v-if="isNeedIndex === 5" class="searchBox">
        <!-- 左边的logo -->
        <h1 class="left">
          <router-link :to="{ path: '/' }">
            <img :src="$localPicture+'logo/logo_black.png'" alt="logo">
          </router-link>
        </h1>
        <span class="catLink">
          {{ payStyle === "00" ? "订单支付" : "保证金支付" }}
        </span>
        <el-steps :active="1" align-center />
      </div>
      <div v-else-if="isNeedIndex !== 3" class="searchBox">
        <!-- 左边的logo -->
        <h1 class="left">
          <router-link :to="{ path: '/' }" @click.native="getActiveNav('0')">
            <img :src="$localPicture+'logo/logo_black.png'" alt="logo">
          </router-link>
        </h1>
        <span v-if="isNeedIndex === 2" class="catLink">
          <!-- <img src="@/assets/icon/cat2.png" alt="cat2"> -->
          购物车
        </span>
        <img v-if="isNeedIndex !== 2" class="img-up" src="@/assets/image/369up.png" alt="">
        <!-- 中间搜索框的 -->
        <div class="search">
          <div class="input-with-select">
            <el-input v-model="keyword" type="text" :placeholder="searchSelectValue==='03'?'请输入商家名称':'请输入商品名称/品类/规格/型号'">
              <el-select slot="prepend" v-model="searchSelectValue" placeholder="请选择" @change="changeSpotType">
                <el-option label="全部" value="0" />
                <el-option label="钢材" value="01" />
                <el-option label="塑料" value="02" />
                <el-option label="店铺" value="03" />
              </el-select>
              <span slot="append" class="search-btn" @click="searchGoods">
                搜 索
              </span>
            </el-input>
          </div>
          <!-- <p v-if="isNeedIndex!==1&&isNeedIndex!==2" class="hot">
          <router-link v-for="item in 8" :key="item" :to="{ path: '/' }">
            普板
          </router-link>
        </p> -->
        </div>
        <!-- 购物车的呀 -->
        <div
          v-if="isNeedIndex !== 2"
          class="right"
          :to="{ path: '/shoppingCart/index' }"
          @click="skipCart"
        >
          <img
            :style="[cartNum > 0 ? 'margin-right:24px' : '']"
            src="@/assets/icon/cat2.png"
            alt="cat"
          >
          <span>购物车</span>
          <i v-if="cartNum > 0">{{ cartNum > 99 ? "99+" : cartNum }}</i>
        </div>
        <img class="customer-service" src="@/assets/image/kf.gif" alt="kf">
        <img v-if="isNeedIndex !== 2" class="weixin" src="@/assets/image/weixin.png" alt="">
      </div>
      <!-- 购物车 -->
      <div v-else class="searchBox">
        <!-- 左边的logo -->
        <h1 class="left">
          <router-link :to="{ path: '/' }">
            <img :src="$localPicture+'logo/logo_black.png'" alt="logo">
          </router-link>
        </h1>
        <span class="catLink">
          <!-- <img src="@/assets/icon/wenben.png" alt="cat2"> -->
          确认订单
        </span>
        <el-steps :active="3" align-center>
          <el-step title="确认订单信息" description="" />
          <el-step title="支付订单" description="" />
          <el-step title="货物交付" description="" />
        </el-steps>
      </div>
      <!-- 下面的导航栏 -->
      <div
        v-if="
          isNeedIndex !== 1 &&
            isNeedIndex !== 2 &&
            isNeedIndex !== 3 &&
            isNeedIndex !== 4 &&
            isNeedIndex !== 5
        "
        class="navBox"
      >
        <p class="title">
          <img src="@/assets/icon/line2.png" alt="line">
          全部商品分类
        </p>
        <ul class="nav">
          <li
            v-for="(item, index) in navArr"
            ref="menuNav"
            :key="index"
            :class="activeNav === item.path ? 'active' : ''"
            @click="getActiveNav(item, index)"
            @mouseenter="showChildBox(item,$event,index)"
            @mouseleave="hideChildBox"
          >
            <img v-if="item.src" :src="item.src" alt="">
            <router-link v-if="item.path && !item.child" rel="opener" tag="a" :target="item.target?'_blank':''" :to="{ path: item.path }">
              {{ item.name }}
            </router-link>
            <a v-else-if="item.url && !item.child" rel="opener" tag="a" :target="item.target?'_blank':''" :href="item.url">
              {{ item.name }}
            </a>
            <span v-else-if="item.child">
              {{ item.name }}
              <i :class="filterIcon(index, item.name) ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="fixedShow" class="childBox nav-menu-child" @mouseenter.stop>
      <span v-for="(item, index) in navArr[1].child" :key="index" :class="{'active' : item.path.split('=')[1] === $route.query.goodsType}" :style="`margin-left:${index===0?fixedOffsetWidth : '0'}px`" @click="JumpScm({name: navArr[1].name},item)">
        {{ item.name }}</span>
    </div>
    <div :class="['childBox', {'nav-menu-child' : isShowChildBox}]" @mouseenter="isShowChildBoxClick" @mouseleave="hideChildBox">
      <span v-for="(item, index) in childrenArr.child" :key="index" :class="{'active' : item.path.split('=')[1] === $route.query.goodsType}" :style="`margin-left:${index===0?offsetWidth:'0'}px`" @click="JumpScm({name: childrenArr.name},item)">
        {{ item.name }}</span>
    </div>
  </header>
</template>

<script>
import { loginOut } from '@/http/login'
import { mapState } from 'vuex'
import collectionPng from '@/assets/icon/collection.png'
export default {
  data() {
    return {
      typeArr: ['商品', '店铺'],
      hotArr: [
        '三级抗震螺纹钢',
        '圆钢',
        '两切低合金板',
        '普卷',
        '普板',
        '三级螺纹钢',
        '低合金卷',
        '四切低合金板'
      ],
      offsetWidth: 0,
      fixedOffsetWidth: 0,
      activeType: 0,
      activeNav: '/',
      activeIndex: null,
      isShowChildBox: false,
      isLogin: true,
      isNeedIndex: null,
      searchValue: '',
      userInfo: {},
      navArr: [
        { name: '首页', path: '/homePage/index' },
        { name: '现货直销',
          path: '/spotHall/index',
          child: [
            { name: '全部现货', path: '/spotHall/index?goodsType=0' },
            { name: '钢材现货', path: '/spotHall/index?goodsType=01' },
            { name: '塑料现货', path: '/spotHall/index?goodsType=02' }
          ]
        },
        { name: '集采公告', path: '/collection/index' },
        { name: '优选店铺', path: '/storeManagement/index', src: collectionPng },
        { name: '加工服务', path: '/processingServices/index', src: collectionPng },
        { name: '求购大厅', path: '/buyHall/index' },
        // { name: '智慧金融', path: '/proxyMining' },
        // { name: '智慧服务', path: '/service' },
        {
          name: '供应链金融',
          child: [
            { name: '生金链', path: '/fourGoldenTreasures/index?type=0' },
            { name: '采金链', path: '/fourGoldenTreasures/index?type=1' },
            { name: '赊金链', path: '/fourGoldenTreasures/index?type=2' },
            { name: '票金链', path: '/fourGoldenTreasures/index?type=3' }
          ]
        },
        { name: '产融超市', path: '/industryFinance/index', target: true },
        { name: '票据贴现', url: 'https://piaoju.369tecgroup.com/', target: true }
      ],
      childrenArr: {},
      rightArr: [
        { name: '公司官网', url: 'https://www.369tecgroup.com/#/' },
        { name: '商城首页', path: '/' },
        {
          name: '供应链金融',
          child: [
            { name: '生金链', path: '/fourGoldenTreasures/index?type=0' },
            { name: '采金链', path: '/fourGoldenTreasures/index?type=1' },
            { name: '赊金链', path: '/fourGoldenTreasures/index?type=2' },
            { name: '票金链', path: '/fourGoldenTreasures/index?type=3' }
          ]
        },
        {
          name: '买家中心',
          child: [
            { name: '我的订单', url: '/orderManage/orderManageBuyersIndex' },
            {
              name: '我的提单',
              url: '/pickingManage/pickingManageBuyersIndex'
            },
            { name: '我的库存', url: '/inventoryManage/inventoryManageIndex' },
            { name: '我的账单', url: '/fundsManage/settlementAccount' },
            { name: '收票管理', url: '/invoiceManage/collectionManage' }
          ]
        },
        {
          name: '卖家中心',
          child: [
            {
              name: '挂牌管理',
              url: '/hangOutManagement/hangOutManagementIndex'
            },
            { name: '我的订单', url: '/orderManage/orderManageSellerIndex' },
            { name: '我的库存', url: '/inventoryManage/inventoryManageIndex' },
            { name: '开票管理', url: '/invoiceManage/invoicingManage' }
          ]
        }
        // { name: '供应链服务', child: ['任你花', '帮你采'] },
        // { name: '客户服务', child: ['人工服务', '客服热线'] }
      ],
      token: null,
      cartNum: 0,
      payStyle: '',
      keyword: this.$route.query.keyword || '',
      searchSelectValue: this.$route.query.goodsType || '0',
      menuActive: 0,
      fixedShow: false
    }
  },
  computed: {
    ...mapState(['carNum', 'orderPayStyle'])
  },
  watch: {
    carNum: {
      handler(newVal) {
        this.cartNum = newVal
      },
      deep: true,
      immediate: true
    },
    orderPayStyle: {
      handler(newVal) {
        this.payStyle = newVal
      },
      deep: true,
      immediate: true
    },
    '$route.path': {
      handler(newVal) {
        this.activeNav = sessionStorage.activeHomePath = newVal
        switch (newVal) {
          case '/spotHall/spotHallDetail':
            this.isNeedIndex = 6
            break
          case '/shoppingCart/index':
            this.isNeedIndex = 2
            break
          case '/orderManagement/index':
            this.isNeedIndex = 3
            break
          case '/publishPurchase/index':
            this.isNeedIndex = 4
            break
          case '/orderManagement/orderPayment':
            this.isNeedIndex = 5
            break
          case '/news/index':
            this.isNeedIndex = 6
            break
          case '/storeManagement/storeHomepage':
            this.isNeedIndex = 6
            break
          case '/processingServices/processServicesHome':
            this.isNeedIndex = 6
            break
          case '/processingServices/inquiry':
            this.isNeedIndex = 6
            break
          case '/news/newsDetail':
            this.isNeedIndex = 6
            break
          case '/industryFinance/index':
            this.isNeedIndex = 6
            break
          case '/industryFinance/industryFinanceDetails':
            this.isNeedIndex = 6
            break
          case '/industryFinanceDetails':
            this.isNeedIndex = 6
            break
          case '/inancialEnterpriseLoan':
            this.isNeedIndex = 6
            break
          case '/storeManagement/ourStoreGoods':
            this.isNeedIndex = 6
            break
          case '/spotHall/index':
            this.fixedShow = true
            this.$nextTick(() => {
              this.fixedOffsetWidth = this.$refs.menuNav[1].offsetLeft
            })
            break
          default:
            this.getCartNum()
            break
        }
        this.$emit('getHeaderState', this.isNeedIndex)
      },
      immediate: true,
      deep: true
    },
    $route: {
      handler(to, form) {
        this.keyword = to.query.keyword
        if (to.path !== '/spotHall/index') {
          this.fixedShow = false
        }
        if (to.path === '/spotHall/index') this.searchSelectValue = to.query.goodsType
      }
    }
  },
  created() {
    this.token = localStorage.getItem(
      `mallFrontDeskToken${this.$store.state.userCmpRole}`
    )
  },
  mounted() {
    if (this.token) {
      this.isLogin = false
      this.userInfo =
        JSON.parse(
          localStorage.getItem(
            `mallFrontDeskUserInfo${this.$store.state.userCmpRole}`
          )
        ) || {}
      this.getCartNum()
    }
    if (sessionStorage.activeHomePath) {
      this.activeNav = sessionStorage.activeHomePath
    }
  },
  methods: {
    filterIcon(val, name) {
      if (this.menuActive === val && this.isShowChildBox) {
        return true
      }
      if (this.$route.path === '/spotHall/index' && name === '现货直销' && this.fixedShow) {
        return true
      }
      return false
    },
    isShowChildBoxClick() {
      this.isShowChildBox = true
    },
    // 搜索下拉
    changeSpotType(val) {
      this.$router.replace({ query: { goodsType: val, keyword: this.keyword }})
    },
    showChildBox(item, e, index) {
      this.menuActive = index
      if (!item.child) return
      this.childrenArr = item
      this.offsetWidth = e.target.offsetLeft
      if (this.fixedShow && item.name === '现货直销') return
      this.isShowChildBox = true
    },
    hideChildBox() {
      this.isShowChildBox = false
      if (this.$route.path !== '/spotHall/index') this.fixedShow = false
    },
    getCartNum() {
      this.isNeedIndex = null
      if (this.token) this.$store.dispatch('getCarNum')
    },
    searchGoods() {
      // 当搜索店铺的时候就要跳转到店铺
      if (this.searchSelectValue === '03' && this.$route.path !== '/storeManagement/index') {
        const routeUrl = this.$router.resolve({
          path: '/storeManagement/index',
          query: { keyword: this.keyword, goodsType: this.searchSelectValue }
        })
        window.open(routeUrl.href, '_blank')
        return
      }
      if (this.searchSelectValue === '03' && this.$route.path === '/storeManagement/index') {
        this.$router.replace({ query: { keyword: this.keyword }})
        return
      }
      // 当是现货的时候也要进行判断  是不是当前页面啊
      if (this.$route.path !== '/spotHall/index') {
        const routeUrl = this.$router.resolve({
          path: '/spotHall/index',
          query: { goodsType: this.searchSelectValue, keyword: this.keyword }
        })
        window.open(routeUrl.href, '_blank')
        return
      }
      this.$router.replace({ query: { keyword: this.keyword, goodsType: this.$route.query.goodsType, goodsName: this.$route.query.goodsName, code: this.$route.query.code }})
    },
    // 跳转购物车
    async skipCart() {
      if (!this.token || !this.token.length) {
        const confirm = await this.$confirm('未登录，是否立即登录?', '提示', {
          type: 'warning'
        }).catch((err) => err)
        if (confirm !== 'confirm') return
        this.$router.push('/login')
        return
      }
      this.$router.push({ path: '/shoppingCart/index' })
    },
    // 跳转首页
    skipUrl(item) {
      item.url && window.open(item.url)
      item.path && this.$router.push(item.path)
    },
    async JumpScm(item, val) {
      if (item.name === '供应链金融' && !val) return
      if ((item.name === '供应链金融' || item.name === '现货直销') && val && val.path) {
        const routeUrl = this.$router.resolve({
          path: val.path
        })
        switch (item.name) {
          case '供应链金融':
            window.open(routeUrl.href, '_blank')
            break
          default:
            this.$router.push(val.path)
            this.fixedShow = true
            break
        }
        return
      }
      // 以下页面需要登录后查看
      if (!this.token || !this.token.length) {
        const confirm = await this.$confirm('未登录，是否立即登录?', '提示', {
          type: 'warning'
        }).catch((err) => err)
        if (confirm !== 'confirm') return
        this.$router.push('/login')
        return
      }
      let cmpRole = ''
      if (item.name === '买家中心' || item.name === '卖家中心') {
        if (item.name === '卖家中心') cmpRole = '01'
        if (item.name === '买家中心') cmpRole = '02'
      }
      switch (this.userInfo.cmpState) {
        case '01':
          window.open(
            `${this.$projectUrl}login?token=${this.token}&cmpRole=${cmpRole}&path=/orgStructure/enterpriseInformation`,
            '_blank'
          )
          return
        case '-1':
          window.open(
            `${this.$projectUrl}login?token=${this.token}&cmpRole=${cmpRole}&path=/orgStructure/enterpriseInformation`,
            '_blank'
          )
          return
        default: {
          let path = ''
          if (!val) {
            path = '/'
          } else {
            path = val.url
          }
          window.open(
            `${this.$projectUrl}login?token=${this.token}&cmpRole=${cmpRole}&path=${path}`,
            '_blank'
          )
          break
        }
      }
    },
    getActiveNav(item, index) {
      this.menuActive = index
      if (!item.path || item.path === '/spotHall/index') return
      if (item === '0') {
        sessionStorage.activeHomePath = this.activeNav = '/'
        return
      }
      sessionStorage.activeHomePath = this.activeNav = item.path
    },
    handleDropdownClick(command) {
      if (command === 'logOut') {
        this.exit()
      }
    },
    // 退出登录
    exit() {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          loginOut({}, (res) => {
            setTimeout(() => {
              this.$router.push({ path: '/login' })
            }, 1000)
            localStorage.clear()
            sessionStorage.clear()
            res.code === 200
              ? this.$message.success(res.msg)
              : this.$message.error(res.msg)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.headerPageBox {
  position: relative;
  background-color: #fff;
  font-size: 12px;
  color: #787878;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);

  .childBox {
    position: absolute;
    width: 100%;
    z-index: 999;
    height: 0;
    left: 0;
    background-color: #fff;
    transition: 0.4s;
    overflow: hidden;
    &.nav-menu-child {
      height: 42px;
      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
    }
    span{
      height: 100%;
      display: inline-block;
      line-height: 42px;
      align-items: center;
      color: #666;
      font-size: 14px;
      margin-right: 24px;
      cursor: pointer;
    }
    span:hover,.active{
      color: $themeColors;
      font-weight: 700;
    }
  }

  .navBox {
    width: $centerPlateWidth;
    display: flex;
    align-items: flex-end;
    margin: auto;
    .nav {
      display: flex;
      height: 48px;
      li {
        display: flex;
        align-items: center;
        // height: 100%;
        // position: relative;
        a,
        span {
          display: inline-block;
          height: 100%;
          font-size: 16px;
          line-height: 48px;
          padding-right: 32px;
          text-align: center;
          color: $mainFont;
          cursor: pointer;
        }
        img {
          height: 20px;
          width: 20px;
        }
      }
      li:hover,
      .active {
        a,
        span {
          color: $themeColors;
          font-weight: 700;
        }
      }
    }
    .title {
      display: flex;
      align-items: center;
      width: 236px;
      margin-right: 24px;
      height: 48px;
      color: #333333;
      font-size: 16px;
      background-color: $background;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      cursor: pointer;

      img {
        height: 24px;
        width: 24px;
        margin: 0 4px 0 16px;
      }
    }
  }

  .searchBox {
    display: flex;
    align-items: center;
    position: relative;
    // justify-content: space-between;
    padding: 24px 0;
    width: $centerPlateWidth;
    margin: auto;
    .img-up {
      width: 102px;
      height: 30px;
      object-fit: contain;
      padding-left: 8px;
      border-left: 1px solid #D9D9D9;
    }
    .el-steps {
      width: 460px;
      /deep/.is-finish .is-text {
        height: 34px;
        width: 34px;
        color: #fff;
        background-color: $themeColors;
        border: 2px solid $themeColors;
      }
      /deep/.is-horizontal .el-step__line {
        top: 16px;
        left: 67%;
        height: 1px;
        background-color: $borderColor3;
        width: 100px;
      }
      /deep/.is-finish .el-step__line {
        background-color: $themeColors;
        i {
          background-color: $themeColors;
          color: $themeColors;
          border-color: $themeColors;
        }
      }
      /deep/.el-step__main .el-step__title {
        color: $fontColor1;
        font-size: 12px;
        line-height: 1;
        padding-top: 12px;
      }
      /deep/.el-step__main .is-finish {
        color: $mainFont;
      }
      /deep/.is-process .is-text,
      /deep/.is-wait .is-text {
        height: 34px;
        width: 34px;
        color: $fontColor;
        background-color: #fff;
        border: 1px solid $borderColor3;
      }
    }
    .catLink {
      flex: 1;
      color: $mainFont;
      font-size: 20px;
      font-weight: 500;
      // transform: translateX(-16px);
      padding-left: 24px;
      height: 40px;
      line-height: 40px;
      border-left: 1px solid #e7e7e7;
    }
    .right {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      flex: 0 0 112px;
      height: 44px;
      padding: 0 16px;
      // border-radius: 2px;
      border: 1px solid #e7e7e7;
      box-sizing: border-box;
      cursor: pointer;
      color: $fontColor5;
      font-size: 16px;
      img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }

      i {
        position: absolute;
        left: 6px;
        top: 6px;
        font-style: normal;
        text-align: center;
        display: inline-block;
        width: 20px;
        height: 14px;
        line-height: 14px;
        font-size: 12px;
        color: #fff;
        background-color: $themeColors;
        border-radius: 49px;
      }
    }

    .search {
      margin-left: 42px;
      margin-right: 16px;
      position: relative;
      .hot {
        position: absolute;
        top: 50px;
        left: 0;
        font-size: 12px;

        a {
          color: $fontColor1;
          margin-right: 4px;
        }
      }

      .input-with-select {
        width: 410px;
        height: 42px;
        border: 1px solid $themeColors;
        /deep/ .el-input-group__append, /deep/.el-input-group__prepend{
          border-radius: 0;
          border: none;
          background-color: rgba(0,0,0,0);
        }
        /deep/.el-input-group__prepend {
          padding: 0;
          width: 64px;
          position: relative;
          .el-select {
            margin: 0;
            font-size: 14px;
            .el-input__inner {
              padding-right: 0;
              padding-left: 16px;
            }
          }
          &::after {
            content: "";
            width: 1px;
            height: 20px;
            background-color: #D9D9D9;
            position: absolute;
            right: 0;
            top: 12px
          }
        }
        /deep/ .el-input-group__append {
          background-color: $themeColors;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
          padding: 0;
          span {
            display: inline-block;
            width: 100%;
            height: 100%;
            padding: 10px 24px;
            box-sizing: border-box;
          }
        }
        /deep/ .el-input__inner {
          border: none;
          height: 42px;
        }
        /deep/ .el-input__icon {
          width: 16px;
        }
      }
    }

    .left {
      // height: 46px;
      flex: 0 0 180px;
      margin-right: 8px;
      a,
      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .topBox {
    width: 100%;
    background-color: $background;
    color: $fontColor;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: $centerPlateWidth;
      height: 35px;
      margin: auto;

      .right {
        display: flex;
        align-items: center;
        height: 100%;

        .telephoneHotline {
          display: flex;
          align-items: center;
          color: $themeColors;
          font-size: 12px;
          font-weight: 900;
          padding-left: 15px;
          height: 11px;
          line-height: 11px;
          border-left: 1px solid $borderColor2;

          img {
            height: 15px;
            width: 15px;
            margin-right: 5px;
          }
        }

        .rightItem {
          position: relative;
          cursor: pointer;
          height: 30px;
          line-height: 30px;
          display: flex;
          align-items: center;

          .rightLine {
            display: flex;
            align-items: center;
            // padding: 0 18px;
            margin-left: 24px;
            // border-left: 1px solid #ccc;
            height: 10px;
            font-size: 14px;

            .icon {
              margin-left: 4px;
              transition: all 0.2s;
            }

            .collect {
              margin-right: 6px;
              font-weight: 700;
              font-size: 14px;
            }
          }

          .hiddenMenu {
            position: absolute;
            width: 100%;
            background-color: #fff;
            top: 30px;
            right: 0;
            text-align: center;
            border: 1px solid $borderColor2;
            border-top: 0;
            box-sizing: border-box;

            a {
              color: #6c6c6c;
              font-size: 12px;
              display: block;
            }

            a:hover {
              color: $themeColors;
            }
          }
        }

        .rightItem:hover {
          span {
            color: $themeColors;
          }

          .icon {
            transform: rotate(180deg);
            color: $themeColors;
          }
        }
      }

      .left {
        font-size: 14px;
        display: flex;
        align-items: flex-end;
        .userName,
        .toLogin {
          display: inline-block;
          cursor: pointer;
          .icon {
            transition: all 0.2s;
          }
        }
        .userName {
          font-size: 14px;
          transform: translateY(1px);
        }
        a,
        span {
          line-height: 1;
        }

        .userName:hover {
          color: $themeColors;

          .icon {
            transform: rotate(180deg);
          }
        }
      }

      .color {
        color: $themeColors;
      }
      a {
        color: #787878;
      }
    }
  }
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fff;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #e25a4a;
}
.customer-service {
  width: 166px;
  height: 44px;
}
.weixin {
  position: absolute;
  right: 0;
  top: 0px;
  width: 88px;
  height: 105px;
}
</style>
